<template>
    <b-form @submit.stop.prevent="onSubmit" class="border-bottom mb-4">
        <b-form-row>
            <h2 class="text-primary text-uppercase">
                {{ t('checkout_PaymentDetails').toUpperCase() }}
            </h2>
        </b-form-row>

        <b-form-row>
            <b-col sm="6">
                <b-form-group
                    :label="t('checkout_PurchaseNumber')"
                    label-for="input-purchaseOrderNumber"
                >
                    <b-form-input
                        id="input-purchaseOrderNumber"
                        autocomplete="off"
                        :placeholder="t('checkout_PurchaseNumRequired')"
                        v-model="$v.value.purchaseOrderNumber.$model"
                        :state="validateState('purchaseOrderNumber')"
                        maxlength="50"
                        required
                    ></b-form-input>
                </b-form-group>
            </b-col>
        </b-form-row>
        <b-form-row>
            <b-col>
                <b-form-group>
                    <template #label>
                        <div class="d-flex justify-content-between">
                            <span class="label">{{ t('checkout_Notes') }}</span>
                            <span class="text-caption text-gray-darker">
                                {{ noteLength }}/150
                            </span>
                        </div>
                    </template>
                    <b-form-textarea
                        id="note"
                        v-model="value.paymentDetailsNote"
                        rows="4"
                        maxlength="150"
                        no-resize
                    >
                    </b-form-textarea>
                </b-form-group>
            </b-col>
        </b-form-row>
    </b-form>
</template>

<script>
import {required} from 'vuelidate/lib/validators';

export default {
    name: 'PaymentDetails',
    props: {
        value: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        noteLength() {
            const note = this.value.paymentDetailsNote;
            return note ? note.length : 0;
        },
    },
    methods: {
        validateState(name) {
            const {$dirty, $error} = this.$v.value[name];
            return $dirty ? !$error : null;
        },
        isValid() {
            this.$v.$touch();
            return !this.$v.$invalid;
        },
    },
    validations: {
        value: {
            purchaseOrderNumber: {
                required,
            },
        },
    },
    mounted() {},
};
</script>
<style lang="scss" scoped></style>
